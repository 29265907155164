var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CText', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.data,
      expression: "data"
    }],
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "color": _vm.data.color,
      "display": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.text) + " "), _c('CText', {
    attrs: {
      "as": "span"
    }
  }, [_c('inline-svg', {
    staticClass: "icon-program-status",
    attrs: {
      "src": _vm.data.icon,
      "fill": _vm.data.color
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }