<template>
  <CText
    v-show="data"
    py="8px"
    px="16px"
    rounded="8px"
    d="inline"
    :bg="data.color"
    color="#333333"
    white-space="nowrap"
  >
    {{ data.text }}
  </CText>
</template>

<script >
import { computed } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  status: {
    type: String,
    required: true
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const data = computed(() => {
    switch (props.status) {
      case 'done':
        return {
          text: 'Berhasil',
          color: '#B9F7AE'
        };

      case 'pending':
        return {
          text: 'Menunggu Pembayaran',
          color: '#FDF9BC'
        };

      default:
        return {
          text: 'Pembayaran Dibatalkan',
          color: '#F0847A'
        };
    }
  });
  return {
    data
  };
};

export default __sfc_main;
</script>