var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CText', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.data,
      expression: "data"
    }],
    attrs: {
      "py": "8px",
      "px": "16px",
      "rounded": "8px",
      "d": "inline",
      "bg": _vm.data.color,
      "color": "#333333",
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.text) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }