<template>
  <CText
    v-show="data"
    :font-size="['12px', '16px']"
    font-weight="400"
    :color="data.color"
    display="flex"
    gap="8px"
    align-items="center"
  >
    {{ data.text }}
    <CText as="span">
      <inline-svg
        :src="data.icon"
        class="icon-program-status"
        :fill="data.color"
      />
    </CText>
  </CText>
</template>

<script >
import { computed } from '@vue/composition-api';
import iconClock from '@/assets/icons/icon-clock.svg';
import iconCircleCheck from '@/assets/icons/icon-circle-check.svg';
import iconCircleWarn from '@/assets/icons/icon-circle-warn.svg';
const __sfc_main = {};
__sfc_main.props = {
  status: {
    type: String,
    default: ''
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const data = computed(() => {
    switch (props?.status) {
      case 'active':
        return {
          text: 'Aktif',
          color: '#0C72E0',
          icon: iconCircleCheck
        };

      case 'pending':
        return {
          text: 'Pending',
          color: '#DA6D06',
          icon: iconClock
        };

      case 'done':
        return {
          text: 'Selesai',
          color: '#008C81',
          icon: iconCircleCheck
        };

      default:
        return {
          text: 'Gagal',
          color: '#D32737',
          icon: iconCircleWarn
        };
    }
  });
  return {
    data
  };
};

export default __sfc_main;
</script>

<style>
.icon-program-status {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
}

@media(min-width: 767px) {
  .icon-program-status{
    height: 25px;
    width: 25px;
    min-height: 25px;
    min-width: 25px;
  }
}
</style>